import React from "react";
import Layout from "../components/Layout";

// Components
import { Link, graphql } from "gatsby";

const Specialisms = ({ pageContext, data }) => {
  const { specialism } = pageContext;
  const { edges, totalCount } = data.allContentfulStudent;
  const specialismHeader = `${totalCount} ${specialism} student${
    totalCount === 1 ? "" : "s"
  }`;

  return (
    <Layout>
      <div>
        <h1>{specialismHeader}</h1>
        <ul>
          {edges.map(({ node }) => {
            const { slug, studentName } = node;
            return (
              <li key={slug}>
                <Link to={`/students/${slug}`}>{studentName}</Link>
              </li>
            );
          })}
        </ul>
        {/*
              This links to a page that does not yet exist.
              You'll come back to it!
            */}
        <Link to="/specialism">All specialisms</Link>
      </div>
    </Layout>
  );
};

export default Specialisms;

export const pageQuery = graphql`
  query ($specialism: String) {
    allContentfulStudent(filter: { specialism: { in: [$specialism] } }) {
      totalCount
      edges {
        node {
          slug
          studentName
        }
      }
    }
  }
`;
